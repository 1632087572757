import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class TabsTwo extends Component{
    render(){
        let 
        tab1 = "Main skills",
        tab2 = "Experience",
        tab3 = "Technical Skills",
        tab4 = "Personal"; 
        const { tabStyle } = this.props
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                        <Tab>{tab4}</Tab>
                                    </TabList>


                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <a href="#service">CTO/VP Engineering <span> - Developer Lead</span></a>
                                                    I'm experienced with building &amp; nurturing effective development teams, selecting and managing tech stacks and getting hands-on to build and maintain complex projects.
                                                </li>
                                                <li>
                                                    <a href="#service">Web Development<span> - SaaS/Software/Apps and Websites</span></a>
                                                    Large SaaS platforms with ecommerce and complex external systems, CRM and marketing systems, Point of Sale, LMS Plaatforms, Marketplaces or simple websites - you name it. Whether it's working with high-security and strict-regulations like government systems and PCI, or building innovative DApps or cloud systems... I've spent over a decade working full stack building scalable systems using modern languages and frameworks.
                                                </li>
                                                <li>
                                                    <a href="#service">Digital Marketing <span> - Search/Social/Email/Funnels</span></a>
                                                    I've helped retailors sell online, service businesses obtain low-cost customers and run successful affiliate marketing campaigns. Certified in media buying, content marketing, SEO, email marketing and content marketing - I'll show you how to get more 'conversions'.
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="#service">CTO <span>- Realm</span></a> 
                                                   2019 - 2021 - At this gaming tech startup I moved from VP of Engineering to CTO within a year. I architected their serverless and distributed containerized backend, built the front end for their SaaS platform, and integrated QLDB for immutable record keeping of gaming scores. I hired, trained, mentored and reshaped their development team, and architected and oversaw the development of their innovative video stream data extraction technology.
                                               </li>
                                               <li>
                                                   <a href="#service">Consulting <span>- Elevate Consulting / Ministry of Health</span></a> 
                                                   2020 - Working with an experienced team, I helped shape Ministry of Health architecture and decision-making for new cloud-based big data research platform.
                                               </li>
                                               <li>
                                                   <a href="#service">Agency Owner <span>- SoftCorp</span></a> 
                                                   2008 - 2019 - For over a decade, I ran a web development and marketing agency in Victoria, BC. Servicing close to 100 clients, I built a team to develop websites, ecommerce systems, handle digital marketing, and build complex software - including CRMs, POS, LMS and Pharmacy systems.
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>



                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="#service">Front End</a> 
                                                   HTML/CSS, Bootstrap, JavaScript, JQuery and React - As well as dozens of integrations with third-party APIs and libraries
                                               </li>
                                               <li>
                                                   <a href="#service">Back End</a>
                                                    Node, Typescript, PHP, Redux, MySQL, SQL Server, PostgreSQL, MongoDB, DynamoDB, QLDB, Cassandra, AMQP, Memcached, PostGIS - Plus exposure to working on Python, C# and RoR projects, as well as dozens of third-party APIs, Frameworks andn Libraries
                                               </li>
                                               <li>
                                                   <a href="#service">Architecture/Cloud</a>
                                                   AWS, GCP, Azure, Docker, REST, JSON:API, GraphQL, Microservices, Serverless, S3, CloudFront, EC2, ECS, RDS, Elasticache, Lambda, SQS, API Gateway, Kubernetes
                                               </li>
                                               <li>
                                                   <a href="#service">DevOps</a>
                                                   GIT, SVN, Gitlab-CI, CI/CD, Security - Plus plenty of cloud and on-prem sysadmin work (hardware, virtualization, firewalls, DNS) and development methodologies (Agile/Scrum, Kanban, etc.)
                                               </li>

                                           </ul>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="#service">Family</a> 
                                                   Born 1987, Married, 2 dogs, 2 cats, no kids
                                               </li>
                                               <li>
                                                   <a href="#service">Identity</a>
                                                    Heterosexual white male (he/him), LGBTQ+ Friendly, #blacklivesmatter
                                               </li>
                                               <li>
                                                   <a href="#service">Hobbies/Interests</a>
                                                    Home improvement, audible, blinkist, geocaching, hiking, trading (fx/stocks), writing trading algorithms, gaming, blockchain, elearning, woodwork and pinball
                                                 </li>

                                           </ul>
                                       </div>
                                    </TabPanel>




                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsTwo;