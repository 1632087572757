import React, { Component } from "react";

class ContactThree extends Component{
    constructor(props){
        super(props);
        this.state = {
            rnName: '',
            rnEmail: '',
            rnSubject: '',
            rnMessage: '',
        };
    }

    render(){
     
        return(
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <h2 className="title">{this.props.contactTitle}</h2>
                                <p className="description">I am available for full time hire and freelance work. <br/>
                                    Connect with me via skype: <u>ericmacdougall09</u>, <br />
                                    email: <a href="mailto:eric@softcorp.ca"> eric@softcorp.ca</a> <br/>
                                    or linkedin: <a href="https://linkedin.com/in/ericmacdougall">Eric MacDougall on LinkedIn</a></p>
                            </div>
                            <div className="form-wrapper">
                               
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                <img align="right" src="/assets/images/esmall.jpg" style={{height: '200px', marginBottom:'50px'}} alt="Eric MacDougall"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactThree;